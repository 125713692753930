import gsap from 'gsap'
import { throttle } from 'throttle-debounce'

export const particleTrail = {
  previousMousePosition: { x: 0, y: 0 },
  previousTimestamp: 0,

  init: () => {
    if (!document.querySelector('.particle-trail')) return

    window.addEventListener('mousemove', throttle(55, particleTrail.handleMouseMove))
    window.addEventListener('touchmove', throttle(55, particleTrail.handleMouseMove))
    window.addEventListener('click', particleTrail.resetParticles)
  },

  calculateMouseSpeed: (x: number, y: number, timestamp: number) => {
    const distance = Math.sqrt((x - particleTrail.previousMousePosition.x) ** 2 + (y - particleTrail.previousMousePosition.y) ** 2)
    const timeElapsed = timestamp - particleTrail.previousTimestamp

    return (distance / timeElapsed) * 1000 // returns pixels per second
  },

  handleMouseMove: (event) => {
    const currentTimestamp = new Date().getTime()
    const x = event.clientX || event.touches[0].clientX
    const y = event.clientY || event.touches[0].clientY

    const speed = particleTrail.calculateMouseSpeed(x, y, currentTimestamp)

    if (speed > 234) {
      particleTrail.renderParticles(x, y)
    }

    particleTrail.previousMousePosition = { x, y }
    particleTrail.previousTimestamp = currentTimestamp
  },

  renderParticle: (target: HTMLElement) => {
    const nr = gsap.utils.random(1, 7, 1)
    const particleContainer = document.createElement('div')
    const particle = document.createElement('div')

    particle.classList.add('particle')
    particle.classList.add(`particle--${nr}`)
    particleContainer.classList.add('particle-container')
    target.appendChild(particleContainer)
    particleContainer.appendChild(particle)

    gsap.set(particleContainer, {
      rotation: gsap.utils.random(0, 360),
    })

    const xModifier = nr > 4 ? 1 : 2
    const x1 = gsap.utils.random(35, 45) / xModifier
    const x2 = x1 + gsap.utils.random(35, 45) / xModifier
    const x3 = x2 + gsap.utils.random(77, 211) / xModifier

    const tween = gsap.timeline()
      .fromTo(particle, {
        scale: 0,
        x: x1,
      }, {
        // delay: gsap.utils.random(0, 0.5),
        duration: 0.5,
        ease: 'power4.in',
        scale: 1,
        x: x2,
      })
      .to(particle, {
        duration: gsap.utils.random(0.93, 1.37) * 1.7,
        ease: 'power4.out',
        onComplete: () => {
          // particle.remove()
          // particleContainer.remove()
          tween.kill()
          // if (target.children.length === 0) target.remove()
        },
        rotation: gsap.utils.random(-90, 90, 10),
        scale: gsap.utils.random(0.5, 0.8),
        x: x3,
      })
  },

  renderParticles: (x: number, y: number) => {
    const amount = gsap.utils.random(2, 6, 1)
    const particlesContainer = document.createElement('div')

    particlesContainer.classList.add('particles-container')
    document.querySelector('.particles').appendChild(particlesContainer)
    gsap.set(particlesContainer, { left: x, top: y })

    for (let i = 0; i < amount; i++) {
      particleTrail.renderParticle(particlesContainer)
    }
  },

  resetParticles: () => {
    document.querySelectorAll('.particles-container').forEach((particles) => {
      const tween = gsap.to(particles, {
        duration: 0.5,
        ease: 'power4.in',
        onComplete: () => {
          particles.remove()
          tween.kill()
        },
        rotation: gsap.utils.random(-90, 90, 10),
        scale: 0,
      })
    })
  },
}
