import { contentEditable, esbuildReload, isProduction, mediaLabel, overlayGrid, pageRestore } from 'helpers'

import { particleTrail } from 'src/scripts/particle-trail'

document.addEventListener('DOMContentLoaded', (): void => {
  !isProduction && contentEditable.init()
  !isProduction && esbuildReload()
  !isProduction && mediaLabel.init()
  !isProduction && overlayGrid.init()
  pageRestore.init()

  particleTrail.init()
})
