
export const breakpoint = {
  phone: {
    xs: 0,
    s: 321,
    m: 426,
  },
  tablet: 768,
  desktop: {
    xs: 1024,
    s: 1366,
    m: 1600,
    l: 1920,
    xl: 2560,
  },
}

export const contentEditable = {
  enabled: false,
  selectors: 'h1, h2, h3, h4, h5, h6, p, li',

  init(): void {
    document.addEventListener('keydown', (event) => {
      simultaneousKeyPress.down(event, ['c', 'e'], () => {
        !this.enabled ? this.enableContentEditable() : this.disableContentEditable()
        this.enabled = !this.enabled
      })
    })
    document.addEventListener('keyup', simultaneousKeyPress.up)
  },

  disableContentEditable(): void {
    document.querySelectorAll(this.selectors).forEach((element) => {
      element.setAttribute('contenteditable', 'false')
    })
  },

  enableContentEditable(): void {
    document.querySelectorAll(this.selectors).forEach((element) => {
      element.setAttribute('contenteditable', 'true')
    })
    document.querySelectorAll('a').forEach((element) => {
      element.setAttribute('contenteditable', 'false')
    })
  },
}

export const esbuildReload = () => {
  new EventSource('https://localhost:9000/esbuild').addEventListener('change', e => {
    const { added, removed, updated } = JSON.parse(e.data)
  
    if (!added.length && !removed.length && updated.length === 1) {
      for (const link of document.getElementsByTagName('link') as any) {
        const url = new URL(link.href)
  
        if (url.host === location.host && url.pathname === updated[0]) {
          const next = link.cloneNode()
          next.href = updated[0] + '?' + Math.random().toString(36).slice(2)
          next.onload = () => link.remove()
          link.parentNode.insertBefore(next, link.nextSibling)
          return
        }
      }
    }
  
    location.reload()
  })
}

export const isProduction = process.env.NODE_ENV === 'production'

export const mediaLabel = {
  init(): void {
    document.body.insertAdjacentHTML('beforeend', '<div class="media-label"></div>')
  },
}

export const overlayGrid = {
  el: {
    grid: null,
    button: null,
  },

  init(): void {
    this.renderGrid()
    this.el.button = document.querySelector<HTMLButtonElement>('.overlay-grid__button')
    this.el.grid = document.querySelector<HTMLElement>('.overlay-grid')
    this.handleButton()
    this.handleKeys()
  },

  handleButton(): void {
    this.el.button.addEventListener('click', () => {
      this.el.button.classList.toggle('overlay-grid__button--active')
      this.el.grid.classList.toggle('overlay-grid--active')
    })
  },

  handleKeys(): void {
    document.addEventListener('keydown', (event) => {
      simultaneousKeyPress.down(event, ['g', 'r'], () => {
        this.el.button.classList.toggle('overlay-grid__button--active')
        this.el.grid.classList.toggle('overlay-grid--active')
      })
    })
    document.addEventListener('keyup', simultaneousKeyPress.up)
  },

  renderGrid(): void {
    document.body.insertAdjacentHTML(
      'beforeend',
      `
      <div class="overlay-grid">
        <div class="container overlay-grid__container">
          <div class="grid grid--gap">
            ${Array.from({ length: 12 }, () => '<div class="overlay-grid__column"></div>').join('')}
          </div>
        </div>
        <button type="button" class="overlay-grid__button">||||||</button>
      </div>
    `
    )
  },
}

export const pageRestore = {
  init(): void {
    // Page restore for mobile iOS - without this back/forward buttons won't hide loader covers.
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        window.location.reload()
      }
    })
  },
}

export const simultaneousKeyPress = {
  pressedKeys: new Set(),

  down(event: KeyboardEvent, keys: Array<string>, callback: any): void {
    const key = event.key.toLowerCase()
    this.pressedKeys.add(key)

    if (keys.every((keyToDetect) => this.pressedKeys.has(keyToDetect))) {
      callback()
      this.pressedKeys.clear()
    }
  },

  up(event: KeyboardEvent): void {
    const key = event.key.toLowerCase()
    this.pressedKeys && this.pressedKeys.delete(key)
  },
}
